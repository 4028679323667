<template>
  <div>
    <div class="outer-wrapper">
      <div class="container-fluid">
        <div class="row justify-content-center mob-row">
          <div class="col-sm-12 col-lg-8 pl-0 bg-black pr-0">
            <div class="col-md-12 logo-header">
              <div class="brand-logo-mob text-left">
                <img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid">
              </div>
            </div>
            <div class="plan-holder">
              <div class="mx-auto text-center">
                <MasterSVG />
                <div class="arrow" v-if="!selectedTypology.key">
                  <DownArrow />
                </div>
              </div>
              <!-- <img src="@/assets/images/layout.jpg" class="project-img" alt="" style="width:100%;"> -->
              <div class="progress-bar-section">
                <ProgressBar :steps="formSteps" :activeStepNo="1" />
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 right-container">
            <div class="side-menu next">
              <div class="row info-wrapper mob-hide">
                <div class="col-12">
                  <p class="page-sub-heading">EOI Booking</p>
                  <h2 class="page-heading">Live Marvellous at Balewadi</h2>
                  <p class="align-data">Pune, Maharashtra</p>
                </div>
              </div>
              <Typologies />
              <div class="row powered-by">Powered by <span
                  class="powered-by-ppistol ml-1">PropertyPistol&nbsp;Technologies</span></div>
            </div>
          </div>
        </div>
        <div class="row powered-by powered-by-mob">Powered by <span
            class="powered-by-ppistol ml-1">PropertyPistol&nbsp;Technologies</span></div>
      </div>
    </div>
    <ArialView />
  </div>
</template>
<script>
import { FormSteps } from '@/assets/scripts/utility.js';
export default {
  components: {
    ProgressBar: () => import('@/components/ProgressBar.vue'),
    MasterSVG: () => import('@/components/MasterSVG.vue'),
    Typologies: () => import('@/components/Home/Typologies.vue'),
    ArialView: () => import('@/components/Home/AerialView.vue'),
    DownArrow: () => import('@/components/Common/DownArrow.vue')

  },
  data() {
    return {
      formSteps: FormSteps
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
  },
  methods: {
    typologyPlanSrc() {
      return require(`@/assets/images/floorplan.jpg`);
    }
  },
  mounted() {
    this.$store.dispatch('setLoading', false);
  },
};
</script>
<style scoped>
.bg-black {
  background-color: #efefef;
}

.project-img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.image-holder {
  position: relative;
  width: auto;
  overflow: hidden;
  height: auto;
}

.powered-by {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  color: #00000080;
}

.arrow {
  position: absolute;
  top: 10%;
  width: 150px;
  height: 150px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.powered-by-mob {
  display: none;
}


.info-wrapper.mob-show,
.mob-show {
  display: none;
}

.powered-by .powered-by-ppistol {
  color: #000000;
}

.compass-img {
  margin-left: auto;
  margin-top: auto;
}

.compass-img-mob {
  display: none;
}

.side-menu {
  border: none;
}

.generate-eoi {
  background: #494899;
  border-radius: 4px;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
}

.generate-eoi:hover {
  /* background: #615FB4;
	color: #FFFFFF; */
}

.card-info {
  /* position: absolute; */
  width: 100%;
  /* height: 83px; */
  margin: 2px;
  background: #FFFFFF;
  border: 1px solid rgba(73, 72, 153, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 16px;
  padding: 16px;
}

.info-wrapper .align-data {
  font-size: 13px;
  color: #000000;
  line-height: 16px;
  text-transform: none;
  opacity: 0.5;
  padding-left: 0px;
  padding-top: 0;
  margin-bottom: 0;
}

.info-wrapper .page-heading {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  font-weight: 700;
  font-size: 24px;
}

.info-wrapper p {
  font-size: 13px;
  color: #000000;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 0.5;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 14px;
}

.No-padding {
  margin: 0;
  padding: 0;
}

.info-wrapper {
  background: #fff;
  padding-right: 15px;
  /* padding: 16px; */
  /* border-radius: 8px; */
  display: flex;
}

.right-container {
  display: block;
  padding: 30px;
  position: relative;
  background: #FFFFFF;
}

.logo-header {
  display: flex;
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.outer-wrapper {
  margin: 0;
  background: #f6f6f6;

}

.plan-holder {
  border-radius: 10px;
  padding: 0;
  background: transparent;
}

@media (min-width:992px) {
  .plan-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    margin-top: 8vh;
  }

  .side-menu {
    padding-top: 0;
    margin-top: 0 !important;
  }

  .progress-bar-section {
    width: 100%;
    position: absolute;
    bottom: -50px;
    max-width: 90%;
  }

  .right-container {
    overflow-y: auto;
    height: 100vh;
  }
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu {
  min-height: 650px;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  padding: 20px;
  margin: revert;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}

.form-wrapper {
  padding: 0 20px;
}

.form-wrapper label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}

.form-wrapper select {
  background: url(../assets/images/caret-icon.svg) no-repeat right .75rem center #FFFFFF;
  border: 1px solid #E7EBF0;
  border-radius: 3px;
  padding: 10px;
  appearance: none;
  height: auto;
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}

.sub-head {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
  display: block;
}

.card-foot {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.card-footer-head span {
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
}

h5 {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}

.link-btn {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@media (max-width:991px) {

  .project-img {
    max-height: 500px;
  }

  .side-menu {
    margin-top: 0 !important;
    min-height: unset;
  }

  .mob-row .col-sm-12 {
    position: static;
  }

  .mob-hide {
    display: none;
  }

  .info-wrapper.mob-show {
    padding: 104px 20px 30px 5px;
    background: #FFFFFF;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

  .row.mob-show {
    display: flex;
  }

  .mob-show {
    display: unset;
  }

  .right-container {
    position: static;
    padding-top: 0;
    padding-bottom: 0;
  }

  .logo-header {
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  }

  .logo-header h3 {
    margin: auto;
  }

  .brand-logo-mob {
    position: absolute;
  }

  .powered-by {
    display: none;
  }

  .powered-by.powered-by-mob {
    display: block;
    position: static;
    margin: auto;
    text-align: center;
    transform: none;
    margin-top: 40px;
    margin-bottom: 160px;
  }

  .compass-img {
    display: none;
  }

  .compass-img-mob {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .right-container {
    padding-left: 0;
    padding-right: 0;
  }

  .progress-bar-section {
    padding: 0 15px 20px 15px
  }

}
</style>